// million-ignore
import React, { MouseEventHandler } from 'react';

import { default as ArrowIcon } from '@ui-kit/icons/dist/icons/ArrowIcon';
import { default as LiveMatch } from '@ui-kit/icons/dist/icons/LiveMatchIcon';
import {
	NewTextType,
	NewUiText,
} from '@ui-kit/main-frontend-ui-kit/dist/src/components/New-ui-text';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import { useLocale, useTranslations } from 'next-intl';

import styles from './TopSection.module.scss';

import { sendAnalytics, trimSymbol } from '@utils/helpers';
import { TopSectionIds } from '@utils/types';

const SectionDropdown = dynamic(
	() =>
		import(
			'@ui-kit/main-frontend-ui-kit/dist/src/components/SectionDropdown'
		).then(m => m.SectionDropdown),
	{
		ssr: true,
	},
);

interface ITopSectionProps {
	section: any;
	onSubsectionClick: MouseEventHandler<HTMLInputElement>;
}

export const TopSection: React.FC<ITopSectionProps> = ({
	section,
	onSubsectionClick,
}) => {
	const t = useTranslations('translations');
	const locale = useLocale();
	const hasSubsections = !!section?.children;
	const sectionId = section?.name;
	const sectionHref = section?.link;
	return (
		<div className={styles['top-section']}>
			<input
				className={styles['top-section__radio']}
				id={sectionId}
				type="radio"
				name="openedSubsection"
				value={sectionId}
				onClick={onSubsectionClick}
			/>
			<label htmlFor={sectionId}>
				{sectionId === TopSectionIds.Apps ? (
					<NewUiText
						type={NewTextType.Subtitle_4}
						className={styles['top-section__container']}
						onClick={() => {
							window.open(`https://tribuna.com/${locale}/our-apps/`, '_blank');
						}}
					>
						{t(section?.name)}
					</NewUiText>
				) : (
					<a
						href={sectionHref}
						className={clsx({
							[styles['top-section__container']]: true,
							[styles['top-section__container--with-subsections']]:
								hasSubsections,
							[styles['top-section__container--with-live-icon']]:
								sectionId === TopSectionIds.Live,
						})}
						onClick={() => {
							sendAnalytics({
								category: 'menu',
								name: 'click',
								label: 'main',
								value: trimSymbol(sectionHref, '/'),
							});
						}}
					>
						{sectionId === TopSectionIds.Live && (
							<LiveMatch className={styles['top-section__live']} />
						)}
						<NewUiText type={NewTextType.Subtitle_4}>
							{t(section?.name)}
						</NewUiText>
						{hasSubsections && (
							<ArrowIcon
								className={styles['top-section__arrow']}
								width={16}
								height={16}
							/>
						)}
					</a>
				)}
			</label>
			{hasSubsections && (
				<SectionDropdown
					className={styles['top-section__dropdown']}
					section={section}
					isMainMenu
				/>
			)}
		</div>
	);
};

export default TopSection;
